.custom-upload .ant-upload {
    width: 87px !important;
    height: 88px !important;
}

.custom-upload .ant-upload.ant-upload-select-picture-card {
    width: 87px !important;
    height: 88px !important;
    line-height: 80px !important;
}

/* Для изменения размеров изображения внутри */
.custom-upload .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    width: 100%;
    height: auto;
}

.custom-upload .ant-upload-select {
    background: #E1F1FE !important;
    border: 1px solid #86C9FF !important;
}