.choose_date {
    padding: 20px;
    text-align: center;
    h2 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
    .choose_date__day {
        height: 150px;
        overflow-y: auto;

        .choose_date__day__times {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: 1fr 1fr;

            button {
                padding: 5px;
                border-radius: 10px;
                background: none;
                border: 1px solid #c5c5c5;
            }
            .active {
                background: linear-gradient(90deg, rgb(224, 243, 232) 0%, rgb(238, 247, 229) 50%, rgb(230, 242, 197) 100%);
            }
        }
    }
}
.ant-picker-dropdown {
    text-align: center;
}