.main_layout {
    .main_layout__header {
        display: flex;
        padding: 20px;
        background: #fff;
        position: relative;
        align-items: center;
        justify-content: center;
        img {
            top: 3px;
            left: 10px;
            width: 52px;
            height: 52px;
            position: absolute;
        }
        p {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
        }
    }
    .main_layout__content {
        padding: 12px 12px 45px 12px;
        overflow-y: auto;
        max-height: calc(100vh - 110px);
    }
    .main_layout__navbar {
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1000;
        padding: 18px 0;
        background: #fff;
        position: absolute;
        ul {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            li {
                text-align: center;
                a {
                    svg {
                        color: #000;
                        font-size: 30px;
                    }
                    p {
                        color: #000;
                    }
                }
            }
            .main_layout__navbar_active {
                a {
                    svg {}
                    p {}
                }
            }
        }
    }
}