.session {
    gap: 16px;
    width: 100%;
    color: #000;
    display: flex;
    height: 120px;
    background: #fff;
    border-radius: 18px;
    .session__item__block {
        .session__item_img {
            width: 70px;
            height: 70px;
            position: relative;
            border-radius: 50%;
            margin: 7px 0 0 10px;
           
            background: rgb(255,198,238);
            background: linear-gradient(137deg, rgba(255,198,238,1) 0%, rgba(238,247,229,1) 50%, rgba(230,242,197,1) 100%);

            img {
                top: 50%;
                left: 50%;
                width: 60px;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
    }
    .session__item__info {
        .date {
            margin-top: 7px;
            font-size: 11px;
            font-weight: bold;
        }
        .title {
            font-size: 15px;
            margin-top: 10px;
            font-weight: bold;
        }
    }
    .session__item__info__btn {
        margin-top: 18px;
        button {
            gap: 10px;
            display: flex;
            color: #000;
            font-size: 13px;
            font-weight: bold;
            padding: 10px 13px;
            align-items: center;
            border-radius: 30px;
            background: #8DE719;
            svg {
                color: #fff;
                font-size: 18px;
            }
        }
    }
}