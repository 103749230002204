.profile {
    .profile_info {
        gap: 30px;
        width: 350px;
        display: flex;
        height: 149px;
        margin: 0 auto;
        margin-top: 15px;
        padding: 0px 11px;
        align-items: center;
        border-radius: 17px;
        box-shadow: 0px 0px 8px 1px #d6d7d7;
        
        ul {
            row-gap: 5px;
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            li {
                gap: 4px;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19.5px;
                    letter-spacing: -1.9%;
                    font-optical-sizing: auto;
                    font-family: "Montserrat", sans-serif;
                }
            }
            .profile_info_user_id {
                svg {
                    color: #e98410;
                }
            }
            .profile_info_user {
                svg {
                    color: #5be232;
                }
            }
            .profile_info_username {
                svg {
                    color: #e92b35;
                }
            }
        }
    }
    .profile__priority_psychology {
        margin-top: 30px;
        .profile__priority_psychology_block {
            height: 70px;
            padding: 15px;
            display: flex;
            border-radius: 10px;
            align-items: center;
            justify-content: space-between;
            background: linear-gradient(90deg, rgb(224, 243, 232) 0%, rgb(238, 247, 229) 50%, rgb(230, 242, 197) 100%);
            .profile__priority_psychology__text {
                display: grid;
                grid-gap: 10px;
                p {
                    &:first-child {}
                    &:last-child {
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }
            .profile__priority_psychology__icon {
                svg {
                    font-size: 25px;
                }
            }
        }
        .profile__priority_psychology_block_active {
            border-radius: 10px 10px 0px 0px;
        }
        .profile__priority_psychology_content {
            display: grid;
            grid-gap: 7px;
            padding: 23px;
            height: 250px;
            overflow-y: auto;
            background: #fff;
            grid-template-rows: auto;
            border-radius: 0px 0px 10px 10px;
            button {
                display: grid;
                grid-gap: 5px;
                padding: 10px;
                font-size: 15px;
                font-weight: bold;
                position: relative;
                border-radius: 14px;
                background: linear-gradient(90deg, rgb(224, 243, 232) 0%, rgb(238, 247, 229) 50%, rgb(230, 242, 197) 100%);
                .full_name {
                    font-weight: bold;
                }
                .speciality {
                    font-size: 13px;
                    font-weight: 300;
                }
                img {
                    width: 30px;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                }
            }
        }
    }
    .profile__sessions {
        display: grid;
        grid-gap: 5px;
        margin-top: 8px;
        grid-template-rows: auto;
        .profile__session {
            padding: 15px;
            border-radius: 10px;
            background: linear-gradient(90deg, rgb(224, 243, 232) 0%, rgb(238, 247, 229) 50%, rgb(230, 242, 197) 100%);
            .profile__session_title {
                font-size: 15px;
                font-weight: bold;
            }
            .profile__session_desc {
                margin-top: 13px;
            }
            button {
                width: 200px;
                height: 50px;
                font-size: 13px;
                margin-top: 12px;
                font-weight: bold;
                border-radius: 35px;
                background: #b0ff03;
            }
        }
    }
    .profile__menu {
        display: grid;
        grid-gap: 16px;
        margin-top: 20px;
        grid-template-rows: auto;
        a {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                color: #000;
                font-size: 17px;
            }

            svg {
                color: #000;
                font-size: 18px;
            }
        }
    }
}