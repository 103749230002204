.direction {
    display: flex;
    padding: 30px 20px;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    background: rgb(224,243,232);
    background: linear-gradient(90deg, rgba(224,243,232,1) 0%, rgba(238,247,229,1) 50%, rgba(230,242,197,1) 100%);
    p {
        color: #000;
        font-size: 15px;
        font-weight: bold;
    }
    svg {
        color: #000;
        font-size: 25px;
    }
}