.menu {
    width: 100%;
    height: 38px;
    padding: 2px;
    display: grid;
    margin-top: 12px;
    border-radius: 10px;
    grid-template-columns: 1fr 1fr;
    background: rgb(224,243,232);
    background: linear-gradient(90deg, rgba(224,243,232,1) 0%, rgba(238,247,229,1) 50%, rgba(230,242,197,1) 100%);
    button {
        background: none;
        font-weight: bold;
        border-radius: 10px;
    }
    .menu__navigate__active {
        background: #fff;
    }
}