.pay_page {
    .pay_page__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .pay_page__info__title {
            font-size: 15px;
            font-weight: bold;
        }
        .pay_page__info__price {
            font-weight: bold;
            font-size: 15px;
        }
    }
    .email_title {
        margin-top: 10px;
        gap: 5px;
        display: flex;
        align-items: center;
    }
    .pay_page__email_block {
        gap: 10px;
        padding: 4px;
        display: flex;
        margin-top: 4px;
        border-radius: 8px;
        justify-content: center;
        // border: 1px solid #86C9FF;
        input {
            padding: 5px;
            width: 100%;
            height: 40px;
        }
    }
    .pay_page__agreement {
        gap: 12px;
        display: flex;
        margin-top: 90px;
        margin-left: 15px;
        align-items: center;
        justify-content: center;
        p {
            font-size: 13px;
        }
        a {
            color: #1b7b1b;
        }
    }
    .pay_page__btn {
        margin-top: 20px;
        text-align: center;
        button {
            height: 60px;
            width: 215px;
            color: grey;
            font-size: 2px;
            font-weight: bold;
            border-radius: 40px;
            background: rgb(230, 242, 197);
        }
    }
}