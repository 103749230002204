.msg_send {
    float: right;
    max-width: 295px;
    justify-self: end;
    width: max-content;
    height: max-content;
    border-radius: 15px;
    padding: 14px 18px 8px;
    background: rgb(230, 242, 197);

    .msg_send__text {
        font-size: 15px;
    }
    .msg_send__date {
        font-size: 12px;
        text-align: right;
    }
}

.msg_received {
    float: left;
    max-width: 295px;
    width: max-content;
    background: #fff;
    justify-self: start;
    height: max-content;
    border-radius: 15px;
    padding: 14px 18px 8px;

    .msg_received__text {
        font-size: 15px;
    }
    .msg_received__date {
        font-size: 12px;
        text-align: left;
    }
    &:last-child {
        margin-bottom: 100px;
    }
}

.chat__send_message_img {
    float: right;
    margin-top: 10px;
    max-width: 320px;
    justify-self: end;
    border-radius: 10px;
    background: rgb(230, 242, 197);
    padding: 10px 10px 6px;
    
    p {
        float: right;
        color: #000;
        margin-top: 5px;
        font-size: 12px;
        font-style: normal;
        font-optical-sizing: auto;
        font-family: "Montserrat", sans-serif;
    }
    img {
        width: 100%;
        height: 100%;
    }
}

.chat__receiver_message_img {
    float: left;
    margin-top: 10px;
    max-width: 320px;
    border-radius: 10px;
    justify-self: start;
    background: #fff;
    padding: 10px 10px 6px;
    
    p {
        float: left;
        color: #000;
        margin-top: 5px;
        font-size: 12px;
        font-style: normal;
        font-optical-sizing: auto;
        font-family: "Montserrat", sans-serif;
    }
    img {
        width: 100%;
        height: 100%;
    }
    &:last-child {
        margin-bottom: 100px;
    }
}

.chat__send_message_file {
    float: right;
    display: grid;
    text-align: left;
    margin-top: 10px;
    max-width: 320px;
    justify-self: end;
    border-radius: 10px;
    padding: 10px 10px 6px;
    background: rgb(230, 242, 197);
    button {
        color: #333;
        margin-top: 5px;
        text-align: left;
        background: none;
    }
    p {
        &:last-child {
            float: right;
            color: #000;
            margin-top: 5px;
            font-size: 12px;
            font-style: normal;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
    }
}

.chat__receiver_message_file {
    float: left;
    display: grid;
    text-align: left;
    margin-top: 10px;
    max-width: 320px;
    justify-self: start;
    border-radius: 10px;
    background: #fff;
    padding: 10px 10px 6px;
    button {
        color: #333;
        margin-top: 5px;
        text-align: left;
        background: none;
    }
    p {
        &:last-child {
            float: left;
            color: #000;
            margin-top: 5px;
            font-size: 12px;
            font-style: normal;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
    }
}