.documents {
    .documents__general, .documents__certificates {
        display: grid;
        grid-gap: 10px;
        margin-top: 25px;
        grid-template-rows: auto;
        a {
            gap: 40px;
            display: flex;
            padding: 18px;
            background: #fff;
            border-radius: 10px;
            align-items: center;
            justify-content: space-between;
            p {
                color: #000;
                font-size: 15px;
                font-weight: bold;
            }
            svg {
                color: #000;
                font-size: 14px;
            }
        }
    }
}