.my_sessions {
    h3 {
        font-size: 19px;
        font-weight: bold;
        text-align: center;
    }
    .my_sessions__navigate {
        width: 100%;
        height: 38px;
        padding: 2px;
        display: grid;
        margin-top: 12px;
        border-radius: 10px;
        grid-template-columns: 1fr 1fr;
        background: rgb(224,243,232);
        background: linear-gradient(90deg, rgba(224,243,232,1) 0%, rgba(238,247,229,1) 50%, rgba(230,242,197,1) 100%);
        button {
            background: none;
            font-weight: bold;
            border-radius: 10px;
        }
        .my_sessions__navigate__active {
            background: #fff;
        }
    }
    .my_sessions__items {
        display: grid;
        grid-gap: 17px;
        margin-top: 16px;
        grid-template-rows: auto;

        .my_sessions__items__text {
            text-align: center;
        }
    }
}