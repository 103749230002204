.support {
    margin-top: 10px;
    h2 {
        font-size: 19px;
        text-align: center;
    }
    .support__questions {
        width: 100%;
        height: 70px;
        display: flex;
        padding: 14px;
        margin-top: 15px;
        align-items: center;
        border-radius: 10px;
        justify-content: space-between;
        background: linear-gradient(90deg, rgb(224, 243, 232) 0%, rgb(238, 247, 229) 50%, rgb(230, 242, 197) 100%);

        .support__questions__text {
            display: grid;
            grid-gap: 5px;

            p {
                color: #000;
            }
            .support__questions__title {
                font-size: 15px;
                font-weight: bold;
            }
            .support__questions__desc {
                font-size: 15px;
            }
        }
        svg {
            color: #000;
            font-size: 25px;
        }
    }
    .support__support_service {
        padding: 14px;
        margin-top: 15px;
        background: #fff;
        border-radius: 10px;
        h3 {
            font-size: 16px;
            font-weight: bold;
        }
        .support__support_service__description {
            font-size: 12px;
            margin-top: 10px;
        }
        .support__support_service__contact {
            display: grid;
            grid-gap: 10px;
            margin-top: 10px;
            div {
                gap: 8px;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                }
            }
        }
    }
    .support__support_description {
        margin-top: 400px;
        p {
            font-size: 14px;
            text-align: center;
            line-height: initial;
            a {}
        }
    }
}