.welcome {
    height: 100vh;
    padding: 0 20px;
    padding-top: 30px;
    .welcome__page {
        .welcome__page__logo {
            text-align: center;
        }
        .welcome__page__title {
            margin-top: 20px;
            p {
                font-size: 18px;
                font-weight: bold;
                text-align: center;
            }
        }
        .welcome__page__description {
            margin-top: 10px;
            text-align: center;
            p {
                font-size: 15px;
            }
        }
    }
}