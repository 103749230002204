.home_page {
    h3 {
        font-size: 22px;
        font-weight: bold;
    }
    .home_page__tests {
        margin-top: 15px;
        h4 {
            font-size: 15px;
        }
        .home_page__tests_items {
            gap: 10px;
            display: flex;
            max-width: 100%;
            margin-top: 10px;
            overflow-y: hidden;
        }
    }
    .our_directions {
        margin-top: 30px;
        h4 {
            font-size: 24px;
            font-weight: bold;
        }
        .our_directions__items {
            display: grid;
            grid-gap: 8px;
            margin-top: 20px;
            grid-template-rows: auto;
        }
    }
}