.audio_record {
    display: flex;
    align-items: center;
    gap: 15px;
    .sound_wave {
        display: none;
    }

    audio {
        &::-webkit-media-controls-panel {
            background-color: #fff;
        }
        &::-webkit-media-controls-current-time-display {
            display: none;
        }
        &::-webkit-media-controls-volume-slider {
            display: none;
        }
        &::-webkit-media-controls-mute-button {
            display: none;
        }
        &::-webkit-media-controls-settings-button {
            display: none;
        }
        &::-webkit-media-controls-time-remaining-display {
            display: none;
        }
        &::-webkit-media-controls-timeline {
            width: 250px;
        }
    }
}