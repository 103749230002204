.chat {
    gap: 20px;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 12px 12px 45px 12px;
    background-image: url('../../assets/img/chat/bg1.png');

    .chat_messages {
        display: grid;
        grid-gap: 20px;
        padding-bottom: 100px;
    }
    .chat__images {
        width: 100%;
        bottom: 0;
        z-index: 998;
        height: 100px;
        display: flex;
        position: fixed;
        background: #fff;
        align-items: center;
        justify-content: center;
    }
    .uploaded_images {
        width: 100%;
        height: 100px;
        display: flex;
        background: #fff;
        align-items: center;
        justify-content: center;
    }
    .chat__control_block {
        position: fixed;
        bottom: 82px;
        left: 0;
        width: 100%;
        z-index: 999;
        background: #fff;

        .input_handler {
            gap: 15px;
            display: flex;
            padding: 10px 18px;
            align-items: center;
            justify-content: center;
            svg {
                width: 20px;
                height: 20px;
                color: #000;
            }
            input {
                width: 100%;
                height: 30px;
                padding: 12px;
                border-radius: 12px;
                border: 1px solid #b3b3b3;
            }
            .send_icon {
                svg {
                    color: #F24259;
                }
            }
            .message_block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 15px;
            }
        }
        .record_handler {
            display: flex;
            padding: 15px 18px;
            align-items: center;
            justify-content: space-between;
            svg {
                width: 20px;
                height: 20px;
                color: #000;
            }

            .audio_record {
                display: flex;
                align-items: center;
                gap: 15px;
                .audio_record__header {}
                .sound_wave {
                    display: none;
                }
            }
        }
    }
}