.package {
    .package__info {
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        h2 {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
        p {
            font-size: 17px;
            margin-top: 17px;
            text-align: center;
        }
    }
    .package__sessions {
        margin-top: 25px;
        h2 {
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }
        .package__sessions_sessions {
            display: grid;
            grid-gap: 15px;
            margin-top: 15px;
            .package__sessions__session {
                display: flex;
                position: relative;
                padding: 15px 15px;
                align-items: center;
                border-radius: 10px;
                justify-content: space-between;
                background: linear-gradient(90deg, rgb(224, 243, 232) 0%, rgb(238, 247, 229) 50%, rgb(230, 242, 197) 100%);
                .package__session_left {
                    h3 {
                        font-size: 15px;
                        font-weight: bold;
                    }
                    div {
                        p {
                            padding: 4px;
                            font-size: 12px;
                            margin-top: 10px;
                            background: #fff;
                            width: max-content;
                            border-radius: 5px;
                        }
                    }
                }
                .package__session_right {
                    top: 20px;
                    right: 20px;
                    position: absolute;
                    p {
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .package__subscriptions {
        margin-top: 25px;
        h2 {
            font-size: 15px;
            font-weight: bold;
            text-align: center;
        }

        .package__subscriptions_subscriptions {
            display: grid;
            grid-gap: 15px;
            margin-top: 15px;
            .package__subscriptions_subscription {
                display: flex;
                position: relative;
                padding: 15px 15px;
                align-items: center;
                border-radius: 10px;
                justify-content: space-between;
                background: linear-gradient(90deg, rgb(224, 243, 232) 0%, rgb(238, 247, 229) 50%, rgb(230, 242, 197) 100%);
                .package__subscription_left {
                    h3 {
                        font-size: 15px;
                        font-weight: bold;
                    }
                    div {
                        p {
                            padding: 4px;
                            font-size: 12px;
                            margin-top: 10px;
                            background: #fff;
                            width: max-content;
                            border-radius: 5px;
                        }
                    }
                }
                .package__subscription_right {
                    top: 20px;
                    right: 20px;
                    position: absolute;
                    p {
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}